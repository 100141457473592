import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id      : 'pages',
        title   : '',
        type    : 'group',
        icon    : 'pages',
        children: [
            {
                id       : 'account',
                title    : 'Manage Account',
                icon     : 'account_circle',
                type : 'item',
                url  : '/pages/profile'
            },
            {
                id   : 'order-warranty',
                title: 'Order a Warranty',
                type : 'item',
                icon : 'insert_photo',
                url  : '/apps/order-warranty'
            },
            {
                id: 'pages',
                title: 'Manage Orders',
                type: 'collapsable',
                icon: 'attach_money',
                children: [
                    {
                        id   : 'pending-orders',
                        title: 'Pending Orders',
                        type : 'item',
                        icon : 'insert_photo',
                        url  : '/apps/pending-orders'
                    },
                    {
                        id   : 'completed-orders',
                        title: 'Completed Orders',
                        type : 'item',
                        icon : 'insert_photo',
                        url  : '/apps/completed-orders'
                    },
                ]
            },
            {
                id   : 'marketing-material',
                title: 'Marketing Material',
                type : 'item',
                icon : 'ballot',
                url  : '/apps/marketing-material'
            },
           
        ]        
    }
];
