import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Login, User } from '../_models/login.model';
//import { SelectedAddressService } from './selectedAddress.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<Login>;
    public currentUser: Observable<Login>;

    constructor(
        private http: HttpClient,
       // private selectedAddressService: SelectedAddressService
       )
    {
        this.currentUserSubject = new BehaviorSubject<Login>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): Login {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {        
        return this.http.post<any>(`${environment.apiUrl}/auth/realtor/login`, { email, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                //this.selectedAddressService.setSelectedAddress(user.user.addresses != null ? user.user.addresses[0] : null);
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentAddress');
        this.currentUserSubject.next(null);
    }

    public UpdateUserData(user: User){
        let newUser = this.currentUserValue;
        newUser["realtor"] = user;
        localStorage.setItem('currentUser', JSON.stringify(newUser));
        this.UpdateUserPic(user.ProfilePicture);
    }
    
    public UpdateUserPic(pic: string){
        let newUser = this.currentUserValue;
        newUser["realtor"].profilePicture = pic;
        localStorage.setItem('currentUser', JSON.stringify(newUser));
    }
}
